@import "~bootstrap/scss/bootstrap";
// @import url("https://fonts.googleapis.com/css2?family=Rajdhani:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

@import "./variables.scss";
body {
  // font-family: "Rajdhani", sans-serif;
  font-family: "Montserrat", sans-serif;
}
header {
  .navbar {
    background-color: var(--color-black);
    box-shadow: 0px 0px 10px -5px #ffffffb8;
    .coinimg {
      height: 30px;
      width: 30px;
    }
    @media (max-width: 767.98px) {
      .navbar-brand {
        width: 42%;
      }
      .coinimg {
        height: 25px;
        width: 25px;
      }
    }
    @media (max-width: 576.98px) {
      .navbar-brand {
        width: 36%;
      }
    }
    .main-grade-btn {
      background-image: linear-gradient(
        to left,
        #c40859,
        #c30073,
        #b40096,
        #9000bd,
        #1f24e6,
        #1f24e6
      );
      color: var(--color-white);
      font-size: 18px;
      font-weight: 500;
      text-transform: uppercase;
      border-radius: 0.2rem;
      transition: color 0.4s ease-in-out, background-color 0.4s ease-in-out,
        border-color 0.4s ease-in-out, box-shadow 0.4s ease-in-out;
      &:hover,
      &:focus,
      &:active,
      &.active {
        background-image: linear-gradient(
          to left,
          #c40859,
          #1f24e6,
          #1f24e6,
          #1f24e6,
          #1f24e6,
          #1f24e6
        );
        border-color: var(--color-purple-lite);
      }
    }
  }
}
footer {
  background-color: #0e1217;
  margin-top: -2px;
}
.hero-banner {
  position: relative;
  padding: 7rem 0 2rem;
  background-image: url(./assets/img/herobg.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: relative;
  background-position: center;
  overflow: hidden;
  margin-top: 6rem;
  .maincount {
    position: relative;
    z-index: 99;
  }
  h1.hero-title {
    font-size: 2.75rem;
  }
  @media (max-width: 767.98px) {
    h1.hero-title {
      font-size: 2rem;
    }
  }
  &::after {
    position: absolute;
    bottom: 0;
    content: " ";
    width: 100%;
    height: 45%;
    background-image: url(./assets/img/herobg-lines.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    animation: herospin 6s infinite;
    z-index: 1;
  }
  @keyframes herospin {
    0% {
      height: 50%;
      transform: rotate(0deg);
    }
    25% {
      height: 55%;
      transform: rotate(5deg);
    }
    50% {
      height: 60%;
      transform: rotate(7deg);
    }
    75% {
      height: 55%;
      transform: rotate(-5deg);
    }
    100% {
      height: 50%;
      transform: rotate(0deg);
    }
  }
  &::before {
    position: absolute;
    bottom: 0;
    content: " ";
    width: 100%;
    height: 45%;
    background-image: url(./assets/img/herobg-lines.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    animation: herospin2 6s infinite;
    z-index: 1;
  }
  @keyframes herospin2 {
    0% {
      height: 51%;
      left: 0;
      right: 0;
    }
    25% {
      height: 56%;
      left: -5%;
      right: 0;
    }
    50% {
      height: 60%;
      left: 0%;
      right: 0%;
    }
    75% {
      height: 54%;
      right: -5%;
      left: 0%;
    }
    100% {
      height: 49%;
      right: 0;
      left: 0;
    }
  }
  .heroimg {
    animation: heroimgrotation 6s infinite;
  }
  @keyframes heroimgrotation {
    0% {
      transform: rotate(0deg);
    }
    25% {
      transform: rotate(5deg);
    }
    50% {
      transform: rotate(7deg);
    }
    75% {
      transform: rotate(-5deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
}
@media (max-width: 767.98px) {
  .hero-banner {
    margin-top: 4.5rem;
  }
}
.flycard {
  background-color: transparent;
  background-image: url(./assets/img/flycard.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
  border-color: transparent;
  &:hover {
    box-shadow: 0px 1px 7px -1px #3d38d99c;
  }
  .card-body {
    min-height: 283px;
    .card-title {
      color: var(--color-white);
      font-size: 18px;
      font-weight: 600;
      min-height: 45px;
    }
    .card-text {
      color: var(--color-white);
      font-size: 14px;
      font-weight: 300;
    }
  }
  @media (max-width: 767.98px) {
    .card-body {
      min-height: 1px;
    }
  }
}
.gamechange {
  background-color: var(--color-black);
  margin-top: -20px;
  overflow: hidden;
  .gamemain {
    background-color: transparent;
    background-image: url(./assets/img/gamechange.png);
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center;
    border-color: transparent;
  }
  .tabscusto {
    .nav-link {
      border: 0;
      background-color: transparent;
      text-transform: capitalize;
      border-right: 1px solid var(--color-white);
      border-radius: 0;
      color: var(--color-white);
      font-weight: 600;
      padding: 0 0.75rem;
      &:hover,
      &:focus,
      &.active {
        color: var(--color-purple);
      }
    }
  }
  .oriowebjac {
    overflow: hidden;
    animation: comeani 6s infinite;
  }
  @keyframes comeani {
    0% {
      transition: scale(0.75) rotate(0deg);
    }
    25% {
      transition: scale(0.85) rotate(90deg);
    }
    50% {
      transition: scale(0.95) rotate(180deg);
    }
    75% {
      transition: scale(0.85) rotate(270deg);
    }
    100% {
      transition: scale(0.75) rotate(360deg);
    }
  }
}
.creshell {
  background-color: transparent;
  background-image: url(./assets/img/creshell.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
  .nftpiramid {
    overflow: hidden;
    animation: comeani 6s infinite;
  }
  @keyframes comeani {
    0% {
      transform: scale(1);
    }
    25% {
      transform: scale(1.1);
    }
    50% {
      transform: scale(1.3);
    }
    75% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(1);
    }
  }
}
.commingsoon {
  background-color: transparent;
  background-image: url(./assets/img/commingsoon.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center -1px;
  .comemain {
    background-color: #060609ad;
    border-radius: 1rem;
  }
  .comeimg {
    background-color: #060609;
    border-radius: 0rem 1rem 1rem 0rem;
    background-image: url(./assets/img/blockchain-2-gif.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
  }

  .orioweb {
    animation: orioweb 6s infinite;
  }
  @keyframes orioweb {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  @media (max-width: 767.98px) {
    .comeimg {
      border-radius: 1rem 1rem 1rem 1rem;
    }
  }
}
.countercard {
  border: 2px solid var(--color-purple);
  background-color: transparent;
  .card-title {
    font-size: 1.85rem;
  }
}
.hwallete-sect {
  background-color: #0f1218;
  background-color: transparent;
  background-image: url(./assets/img/buyorio.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
  .cont-walletbox {
    min-height: 500px;
    display: block;
  }
  @media (max-width: 767.98px) {
    .cont-walletbox {
      min-height: 1px;
      display: block;
      margin-bottom: 1rem;
    }
  }
  .wallet-tab {
    position: relative;
    .wall-conect-btn {
      padding: 0.5rem 1.5rem;
      position: absolute;
      right: 1.5rem;
      margin-bottom: -1px;
      margin-left: 1.5rem;
      font-size: 16px;
      border: 0;
      border-radius: 50px;
      color: var(--color-midblack-mode);
      background-image: linear-gradient(
        to left,
        #c30073,
        #b40096,
        #9000bd,
        #1f24e6,
        #1f24e6,
        #1f24e6
      );
      text-transform: capitalize;
      font-weight: 500;
      text-transform: capitalize;
      box-shadow: 0px -3px 8px -2px #3d38d99c;
      z-index: 1;
      &.active,
      &:hover,
      &:focus,
      &:active {
        color: var(--color-white);
      }
      img {
        width: 20px;
      }
    }
    @media (max-width: 576.98px) {
      .wall-conect-btn {
        padding: 0.3rem 0.75rem;
        right: 0;
        font-size: 13px;
      }
    }
    .nav-tabs {
      border-color: #202020;
      position: relative;
      border: 0;
      .nav-item {
        z-index: 1;
        .nav-link {
          margin-bottom: -1px;
          margin-left: 1.5rem;
          font-size: 18px;
          border: 0;
          border-top-left-radius: 0.5rem;
          border-top-right-radius: 0.5rem;
          color: var(--color-midblack-mode);
          background-color: var(--color-bgwallet-mode);
          font-weight: 500;
          text-transform: capitalize;
          box-shadow: 0px -3px 8px -2px #3d38d99c;
          &.active,
          &:hover,
          &:focus,
          &:active {
            color: var(--color-purple);
          }
        }
        // &:nth-child(3) {
        //   position: absolute;
        //   right: 6.85rem;
        // }

        @media (max-width: 767.98px) {
          .nav-link {
            font-size: 13px;
            padding-left: 0.75rem;
            padding-right: 0.75rem;
          }
          .nav-link {
            margin-left: 0.5rem;
          }
          &:last-child {
            right: 0.5rem;
          }
        }
      }
      @media (max-width: 767.98px) {
        .nav-item {
          z-index: 1;
          .nav-link {
            margin-bottom: -1px;
            margin-left: 0.1rem;
          }
          &:nth-child(3) {
            position: static;
            right: auto;
          }
          &:last-child {
            position: static;
            right: auto;
            .nav-link {
              margin-bottom: -1px;
              margin-left: 0.1rem;
            }
          }
        }
      }
    }
    .tab-content {
      padding: 1.5rem;
      border-radius: 0.5rem;
      background-color: var(--color-bgwallet-mode);
      box-shadow: 0px 1px 7px -1px #3d38d99c;
      .form-label {
        margin-bottom: 0.5rem;
        color: var(--color-white-mode);
        font-size: calc(var(--font-size-base) - 0.125rem);
        font-weight: 400;
        text-transform: capitalize;
        text-align: left;
        display: block;
      }
      .walletdropbutton {
        background-color: #da2b2b12;
        border: 1px solid var(--color-purple);
        box-shadow: none;
      }
      .input-group {
        .form-control {
          font-size: 1rem;
          font-weight: 400;
          color: #878787;
          background-color: #da2b2b12;
          border: 1px solid var(--color-purple);
          border-radius: 0.25rem;
          box-shadow: none;
        }
        .btn,
        .purple-btn {
          min-width: 150px;
          background-color: var(--color-purple);
          border-color: var(--color-purple);
          text-align: center;
          display: inline-block;
        }
      }
      .connectbtn {
        border: 1px solid var(--color-pink-lite);
        color: var(--color-white);
        opacity: 1;
      }
      @media (max-width: 767.98px) {
        .input-group {
          .btn {
            min-width: 70px;
            font-size: 14px;
            padding: 0;
          }
          .purple-btn {
            min-width: 90px;
          }
        }
      }
    }
    .walltemag {
      box-shadow: 0px 0px 3px 0px var(--color-yellow);
      padding: 0.5rem;
    }
  }
}

.home-fixed {
  position: fixed;
  right: 0;
  top: 40px;
  background-color: var(--color-black);
  border-radius: 10px 0px 0px 10px;
  .nav {
    .nav-link {
      color: var(--color-white);
      font-weight: 900;
    }
  }
}

.adminreadform {
  padding: 1.5rem;
  border-radius: 0.5rem;
  background-color: var(--color-bgwallet-mode);
  box-shadow: 0px 1px 7px -1px #3d38d99c;
  .form-control {
    font-size: 1rem;
    font-weight: 400;
    color: #878787;
    background-color: rgba(218, 43, 43, 0.0705882353);
    border: 1px solid #030060;
    border-radius: 0.25rem;
    box-shadow: none;
  }
}

.cotexarea {
  font-size: 0px;
  color: transparent;
  border: 0px;
  background: transparent;
  position: relative;
  &:focus {
    outline: none;
    box-shadow: none;
  }
  &::before {
    content: " ";
    position: absolute;
    top: 0;
    height: 20px;
    width: 30px;
    background-color: red;
  }
}

.my-orio-balence-sec {
  box-shadow: 5px 3px 16px #c30073, -11px -2px 11px #1f24e6;
  font-size: 18px;
}
@media (max-width: 767.98px) {
  .my-orio-balence-sec {
    font-size: 16px;
  }
}
@media (max-width: 576.98px) {
  .my-orio-balence-sec {
    font-size: 12px;
  }
}
